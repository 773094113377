import {UserRoutes} from '../routes/UserRoutes'

const UserTab = () => {
  return (
    <div className='m-0'>
      {/*begin::Projects*/}
      <div className='m-0'>
        {/*begin::Heading*/}
        <h4 className='text-gray-800 mb-6 mx-5'>प्रयोगकर्ता व्यवस्थापन</h4>
        {/*end::Heading*/}

        <div
          className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-5 px-6 my-5 my-lg-0'
          id='kt_aside_menu'
          data-kt-menu='true'
        >
          <div id='kt_aside_menu_wrapper' className='menu-fit'>
            <UserRoutes />
          </div>
        </div>
      </div>
      {/*end::Projects*/}
    </div>
  )
}

export {UserTab}
