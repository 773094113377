import {KTIcon} from '../../../../helpers'
import {Dropdown1, Search} from '../../../../partials'
import {HouseHoldRoutes} from '../routes/HouseHoldRoutes'

const HouseHoldTab = () => {
  return (
    <div className='m-0'>
      <div className='d-flex mb-10'>
        <Search />
        <div className='flex-shrink-0 ms-2'>
          <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>
      <div className='m-0'>
        <h4 className='text-gray-800 mb-6 mx-5'>घरधुरी</h4>
        <div
          className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-5 px-6 my-5 my-lg-0'
          id='kt_aside_menu'
          data-kt-menu='true'
        >
          <div id='kt_aside_menu_wrapper' className='menu-fit'>
            <HouseHoldRoutes />
          </div>
        </div>
      </div>
    </div>
  )
}

export {HouseHoldTab}
