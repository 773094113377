import axios, {AxiosResponse} from 'axios'
import {AuthModel, UserModel} from './_models'
import {toast} from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/profile`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const LOGOUT_URL = `${API_URL}/admin/logout`
export const SETTING_URL = `${API_URL}/admin/profile/user-setting`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}
export function logoutUser() {
  return axios
    .post<{result: boolean}>(LOGOUT_URL)
    .then(function (response: AxiosResponse) {
      toast.success(response.data.message)
    })
    .catch(function (error) {
      toast.error(error.response.data.message)
    })
}
export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    params: {
      token: token,
    },
  })
}
