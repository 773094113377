import {AsideMenuItem} from '../AsideMenuItem'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'

export function PalikaProfileRoutes() {
  return (
    <>
      <AsideMenuItemWithSub to='/palika-profile' title='व्यक्तिगत घटना विवरण' hasBullet={true}>
        <AsideMenuItem
          to='/palika-profile/personal/birth-detail'
          icon='check-circle'
          title='जन्म थप्नुहोस्'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/palika-profile/personal/death-detail'
          icon='check-circle'
          title='मृत्यु थप्नुहोस्'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/palika-profile/irrigation-facility'
        title='सिंचाई सुविधा थप्नुहोस्'
        fontIcon='bi-layers'
        hasBullet={true}
      />

      <AsideMenuItem
        to='/palika-profile/social-allowance-distribution'
        title='सामाजिक भत्ता वितरण'
        fontIcon='bi-layers'
        hasBullet={true}
      />
    </>
  )
}
