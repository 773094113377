import {AsideMenuItem} from '../AsideMenuItem'

export function HouseHoldRoutes() {
  return (
    <>
      <AsideMenuItem
        to='/household/add'
        icon='check-circle'
        title='घरधुरी थप्नुहोस्'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/household/list'
        icon='check-circle'
        title='विवरण हेर्नुहोस'
        fontIcon='bi-layers'
      />
    </>
  )
}
