import api from 'app/api/axiosConfig'
import {AxiosResponse} from 'axios'
import {toast} from 'react-toastify'

class API {
  private readonly path: string

  constructor(url: string) {
    this.path = url
  }

  create = (data: any, reset: () => void) => {
    return new Promise((resolve, reject) => {
      api
        .post(`${this.path}`, data)
        .then(function (response: AxiosResponse) {
          toast.success(response.data.message)
          reset()
          resolve(response.data)
        })
        .catch(function (error: any) {
          const errorMessage = error.response.data.message
          toast.error(errorMessage)
          reject(error)
        })
    })
  }

  get = async () => {
    try {
      const response = await api.get(`${this.path}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  delete = () => {
    return new Promise((resolve, reject) => {
      api
        .delete(`${this.path}`)
        .then((response) => {
          toast.success(response.data.message)
          resolve(response.data)
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          toast.error(errorMessage)
          reject(error)
        })
    })
  }

  update(data: any) {
    return new Promise((resolve, reject) => {
      api
        .put(`${this.path}`, data)
        .then((response) => resolve(response.data))
        .catch((error) => {
          const errorMessage = error.response.data.message
          toast.error(errorMessage)
          reject(error)
        })
    })
  }
}

export default API
