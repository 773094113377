export const queryKeys = {
  fiscalYear: 'fiscal-year-get',
  familyMembers: 'get-all-family-members',
  deathMember: 'get-all-death-member',
  cropProduction: 'get-all-crop-production',
  livestockBusiness: 'get-all-livestock-business',
  houseHold: 'get-household-detail',
  livestockDetail: 'get-all-livestock-detail',
  buildingDetail: 'get-all-building-detail',
  buildingRent: 'get-all-building-rent',
  landDetail: 'get-all-land-detail',
  expense: 'get-all-expense',
  income: 'get-all-income',
  investment: 'get-all-investment',
  loan: 'get-all-loan',
  saving: 'get-all-saving',
  disasterDetail: 'get-all-disaster-detail',
  healthDetail: 'get-all-health-detail',
  schoolDetail: 'get-all-school-detail',
  populationIncomeType: 'get-all-population-income-type',
  barrenLand: 'get-all-barren-land',
  buildingLocatedArea: 'get-all-building-located-area',
  businessTraining: 'get-all-business-training',
  caste: 'get-all-caste',
  businessTrainingCategory: 'get-all-business-training-category',
  checkupPlace: 'get-all-checkup-place',
  chronicDiseaseType: 'get-all-chronic-disease-type',
  communicationTechnology: 'get-all-communication-technology',
  communityType: 'get-all-community-type',
  cookingFuel: 'get-all-cooking-fuel',
  country: 'get-all-country',
  covidVaccine: 'get-all-covid-vaccine',
  crop: 'get-all-crop',
  cropCategory: 'get-all-crop-category',
  disabilityType: 'get-all-disability-type',
  disease: 'get-all-disease',
  drinkingWaterSource: 'get-all-drinking-water-source',
  drinkingWaterStatus: 'get-all-drinking-water-status',
  educationArea: 'get-all-education-area',
  epidemic: 'get-all-epidemic',
  epidemicSymptom: 'get-all-epidemic-symptom',
  ethnicity: 'get-all-ethnicity',
  facility: 'get-all-facility',
  healthOrganizationType: 'get-all-health-organization-type',
  houseChiefRelation: 'get-all-house-chief-relation',
  houseFoundationType: 'get-all-house-foundation-type',
  investmentArea: 'get-all-investment-area',
  investmentType: 'get-all-investment-type',
  irrigationMedium: 'get-all-irrigation-medium',
  irrigationSource: 'get-all-irrigation-source',
  irrigationStatus: 'get-all-irrigation-status',
  landOwnership: 'get-all-land-ownership',
  landType: 'get-all-land-type',
  landUse: 'get-all-land-use',
  lightSource: 'get-all-light-source',
  livestockBusinessType: 'get-all-livestock-business-type',
  livestockType: 'get-all-livestock-type',
  loanSource: 'get-all-loan-source',
  loanUsage: 'get-all-loan-usage',
  maritalStatus: 'get-all-marital-status',
  nativeLanguage: 'get-all-native-language',
  naturalDisaster: 'get-all-natural-disaster',
  occupation: 'get-all-occupation',
  organizationType: 'get-all-organization-type',
  irrigationFacility: 'get-all-irrigation-facility',
  unitType: 'get-all-unit-type',
  unitVariety: 'get-all-unit-Variety',
  unit: 'get-all-unit',
  pesticideUsageMethod: 'get-all-pesticide-usage-method',
  religion: 'get-all-religion',
  roofType: 'get-all-roof-type',
  socialSecurityProgram: 'get-all-social-security-program',
  savingArea: 'get-all-saving-area',
  savingUsage: 'get-all-saving-usage',
  schoolType: 'get-all-school-type',
  skill: 'get-all-skill',
  stove: 'get-all-stove',
  street: 'get-all-street',
  structureType: 'get-all-structure-type',
  toiletType: 'get-all-toilet-type',
  tole: 'get-all-tole',
  transportationMeans: 'get-all-transportation-means',
  vehicleType: 'get-all-vehicle-type',
  wallType: 'get-all-wall-type',
  wasteManagementSystem: 'get-all-waste-management-system',
  wasteWaterManagement: 'get-all-waste-water-management',
  waterFiltrationProcess: 'get-all-water-filtration-process',
  role: 'get-all-role',
  user: 'get-all-user',
  dashboard: 'dashboard',
  birth: 'get-all-birth',
  death: 'get-all-death',
  socialAllowanceDistribution: 'get-all-social-allowance-distribution',
}
