/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {Dispatch, FC, SetStateAction} from 'react'
import {KTIcon} from '../../../helpers'

const tabs: ReadonlyArray<{link: string; icon: string; tooltip: string}> = [
  {
    link: 'household',
    icon: 'home-2',
    tooltip: 'घरधुरी',
  },
  {
    link: 'palika-profile',
    icon: 'clipboard',
    tooltip: 'पालिका प्रोफाइल',
  },
  {
    link: 'user-management',
    icon: 'profile-user',
    tooltip: 'प्रयोगकर्ता व्यवस्थापन',
  },
  {
    link: 'settings',
    icon: 'setting-2',
    tooltip: 'आधारभूत सेटिङ',
  },
]

type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
}

const AsideTabs: FC<Props> = ({link, setLink}) => (
  <div
    className='hover-scroll-y mb-10'
    data-kt-scroll='true'
    data-kt-scroll-activate='{default: false, lg: true}'
    data-kt-scroll-height='auto'
    data-kt-scroll-wrappers='#kt_aside_nav'
    data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
    data-kt-scroll-offset='0px'
  >
    <ul className='nav flex-column' id='kt_aside_nav_tabs'>
      {tabs.map((t) => (
        <li key={t.link}>
          <a
            href='#'
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
              {active: t.link === link}
            )}
            onClick={() => setLink(t.link)}
          >
            <KTIcon iconName={t.icon} className='fs-2x' />
          </a>
        </li>
      ))}
    </ul>
  </div>
)

export {AsideTabs}
