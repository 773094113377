import {getAuth} from 'app/modules/auth'
import axios, {AxiosError} from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
  },
})

const errorHandler = (error: AxiosError) => {
  const statusCode = error.response?.status
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})

api.interceptors.request.use(
  (config) => {
    const auth = getAuth()
    if (auth && auth.token) {
      config.headers.Authorization = `Bearer ${auth.token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api

export function setupAxios(axiosInstance: typeof axios) {
  axiosInstance.defaults.headers.Accept = 'application/json'
  axiosInstance.interceptors.request.use(
    (config) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err) => {
      Promise.reject(err)
    }
  )
}
