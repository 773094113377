const ADMIN_URL = '/admin'

const API_URLS = {
  login: '/login',
  household: `${ADMIN_URL}/household`,
  setting: `${ADMIN_URL}/setting`,
  enums: `${ADMIN_URL}/enums`,
  address: `${ADMIN_URL}/address`,
  institutionalData: `${ADMIN_URL}/institutionalData`,
  role: `${ADMIN_URL}/role`,
  permission: `${ADMIN_URL}/permission`,
  user: `${ADMIN_URL}/user`,
  population: `${ADMIN_URL}/population`,
}

const API_PATHS = {
  dashboard: 'dashboard',
  population: 'population',
  healthCondition: 'health-condition',
  populationDeathDetail: 'populationDeathDetail',
  caste: 'caste',
  houseChiefRelation: 'houseChiefRelation',
  religion: 'religion',
  nativeLanguage: 'nativeLanguage',
  gender: 'gender',
  bloodGroup: 'blood-group',
  occupation: 'occupation',
  maritalStat: 'maritalStatus',
  district: 'district',
  socialSecurityProgram: 'socialSecurityProgram',
  licenseCategoryEnum: 'license-category-enum',
  educationLevel: 'education-level',
  educationArea: 'educationArea',
  eligibilityMod: 'eligibility-mode',
  country: 'country',
  stateOfResidence: 'state-of-residence',
  hospitalWardEnum: 'hospital-ward-enum',
  disease: 'disease',
  epidemic: 'epidemic',
  epidemicSymptom: 'epidemicSymptom',
  postTransitionResidence: 'post-transition-residence',
  covidVaccine: 'covidVaccine',
  businessTraining: 'businessTraining',
  skill: 'skill',
  eligibilityMode: 'eligibility-mode',
  cropProductionDetail: 'cropProductionDetail',
  crop: 'crop',
  investmentDetail: 'investmentDetail',
  investmentType: 'investmentType',
  healthOrganizationDetail: 'healthOrganizationDetail',
  healthOrganizationType: 'healthOrganizationType',
  barrenLandReason: 'barrenLandReason',
  ethnicity: 'ethnicity',
  buildingLocatedArea: 'buildingLocatedArea',
  businessTrainingCategory: 'businessTrainingCategory',
  checkupPlace: 'checkupPlace',
  chronicDiseaseType: 'chronicDiseaseType',
  communicationTechnology: 'communicationTechnology',
  communityType: 'communityType',
  cookingFuel: 'cookingFuel',
  cropCategory: 'cropCategory',
  disabilityType: 'disabilityType',
  drinkingWaterSource: 'drinkingWaterSource',
  drinkingWaterStatus: 'drinkingWaterStatus',
  facility: 'facility',
  fiscalYear: 'fiscalYear',
  houseFoundationType: 'houseFoundationType',
  investmentArea: 'investmentArea',
  irrigationMedium: 'irrigationMedium',
  irrigationSource: 'irrigationSource',
  irrigationStatus: 'irrigationStatus',
  landOwnership: 'landOwnership',
  landType: 'landType',
  landUse: 'landUse',
  lightSource: 'lightSource',
  livestockBusinessType: 'livestockBusinessType',
  livestockType: 'livestockType',
  livestockUsage: 'livestock-usage',
  loan: 'loan',
  loanSource: 'loanSource',
  loanUsage: 'loanUsage',
  maritalStatus: 'maritalStatus',
  naturalDisaster: 'naturalDisaster',
  organizationType: 'organizationType',
  irrigationFacility: 'irrigationFacility',
  unitType: 'unitType',
  unitVariety: 'unitVariety',
  unit: 'unit',
  pesticideUsageMethod: 'pesticideUsageMethod',
  roofType: 'roofType',
  savingArea: 'savingArea',
  savingUsage: 'savingUsage',
  schoolType: 'schoolType',
  stove: 'stove',
  street: 'street',
  structureType: 'structureType',
  toiletType: 'toiletType',
  tole: 'tole',
  transportationMeans: 'transportationMeans',
  vehicleType: 'vehicleType',
  wallType: 'wallType',
  wasteManagement: 'wasteManagement',
  wasteWaterManagement: 'wasteWaterManagement',
  waterFiltrationProcess: 'waterFiltrationProcess',
  role: 'role',
  permission: 'permission',
  user: 'user',
  livestockDetail: 'livestockDetail',
  profile: 'profile',
  houseOwnership: 'house-ownership',
  landManagement: 'land-management',
  electricity: 'electricity',
  communityInvolvement: 'community-involvement',
  drainage: 'drainage',
  regardingExpenses: 'regarding-expenses',
  expenseDetail: 'expenseDetail',
  incomeDetail: 'incomeDetail',
  regardingIncome: 'regarding-income',
  land: 'land',
  roadType: 'road-type',
  reasonForNoCheckup: 'reason-for-no-checkup',
  skillManpower: 'skill-manpower',
  placeForBabyBorn: 'place-for-baby-born',
  babyBornWithHelp: 'baby-born-with-help',
  healthCheckupStatus: 'health-checkup-status',
  livestockBusinessDetail: 'livestockBusinessDetail',
  event: 'event',
  birth: 'birth',
  death: 'death',
  getAllHousehold: 'get-all-household',
  socialAllowanceDistribution: 'socialAllowanceDistribution',
  savingDetail: 'savingDetail',
  building: 'building',
  buildingRentDetail: 'buildingRentDetail',
  disasterDetail: 'disasterDetail',
  householdSchoolDetail: 'householdSchoolDetail',
  dropdown: 'dropdown',
  showById: 'show-by-id',
}

export {API_URLS, API_PATHS, ADMIN_URL}
