import {AsideMenuItem} from '../AsideMenuItem'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'

export function AsideSettingRoutes() {
  return (
    <>
      <AsideMenuItem
        to='/settings/setting'
        title='मुख्य सेटिङ'
        fontIcon='bi-layers'
        icon='setting-2'
      />
      <AsideMenuItemWithSub
        to='/settings/basic'
        title='आधारभूत सेटिङ'
        icon='setting-4'
        fontIcon='bi-layers'
      >
        <AsideMenuItemWithSub to='/settings/personal' title='व्यक्तिगत' hasBullet={true}>
          <AsideMenuItemWithSub to='/settings/personal' title='जात/जाति' hasBullet={true}>
            <AsideMenuItem
              to='/settings/ethnicity'
              title='जातियता समूह'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/caste'
              title='जाति'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/settings/native-language'
            title='मातृभाषा'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/occupation'
            title='पेशा'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/religion'
            title='धर्म'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/marital-status'
            title='वैवाहिक स्थिति'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/social-security-program'
            title='सामाजिक सुरक्षा कार्यक्रम'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/disability-type'
            title='अपाङ्गता प्रकार'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/house/chief/relation'
            title='घर मुलिसँगको सम्बन्ध'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItemWithSub
            to='/settings/business'
            title='व्यवसाहिक तालिम/सिप'
            hasBullet={true}
          >
            <AsideMenuItem
              to='/settings/business/training/category'
              title='तालिमका छेत्र'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/business/training'
              title='तालिमका बिषय'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem to='/settings/skill' title='सिप' fontIcon='bi-layers' hasBullet={true} />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/settings/economics' title='आर्थिक विवरण' hasBullet={true}>
          <AsideMenuItemWithSub to='/settings/investment' title='लगानी' hasBullet={true}>
            <AsideMenuItem
              to='/settings/investment/area'
              title='लगानीको क्षेत्र'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/investment/type'
              title='लगानीको प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/settings/loan' title='ऋण' hasBullet={true}>
            <AsideMenuItem
              to='/settings/loan/source'
              title='ऋणको स्रोत'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/loan/usage'
              title='ऋणको प्रयोजक'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/settings/saving' title='बचत' hasBullet={true}>
            <AsideMenuItem
              to='/settings/saving/area'
              title='बचत क्षेत्र'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/saving/usage'
              title='बचत उपयोग'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/settings/health-and-diseases'
          title='स्वास्थ्य तथा रोगहरु'
          hasBullet={true}
        >
          <AsideMenuItemWithSub to='/settings/disease' title='रोग' hasBullet={true}>
            <AsideMenuItem
              to='/settings/disease/chronical'
              title='घातक रोगहरु'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/disease/types'
              title='रोगहरु'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/settings/epidemic' title='महामारी' hasBullet={true}>
            <AsideMenuItem
              to='/settings/epidemic/types'
              title='महामारीको प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/epidemic/symptom'
              title='महामारीको लक्षणहरु'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/settings/checkup-place'
            title='जाँच गर्ने ठाउ'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/covid-vaccine'
            title='कोभिड-१९'
            fontIcon='bi-layers'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/settings/land-and-building' title='जग्गा र भवन' hasBullet={true}>
          <AsideMenuItemWithSub to='/settings/land' title='जग्गा' hasBullet={true}>
            <AsideMenuItem
              to='/settings/land/type'
              title='जग्गाको प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/land/use'
              title='जग्गाको प्रयोग'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/land/barren-reason'
              title='बाँझो जग्गाको कारण'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/land/ownership'
              title='जग्गाको स्वामित्व'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/settings/irrigation' title='सिंचाई' hasBullet={true}>
            <AsideMenuItem
              to='/settings/irrigation/source'
              title='सिंचाईको स्रोत'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/irrigation/medium'
              title='सिंचाईको माध्यम'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/irrigation/status'
              title='सिंचाईको स्थिति'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/settings/building' title='भवन' hasBullet={true}>
            <AsideMenuItem
              to='/settings/structure-type'
              title='संरचनाको प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/roof-type'
              title='छतको प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/house-foundation-type'
              title='जगको प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/wall-type'
              title='भिता प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/building-located-area'
              title='भवन स्थित क्षेत्र'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/settings/street'
            title='सडक अधिकार क्षेत्र'
            fontIcon='bi-layers'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/settings/infrastructure' title='पूर्वाधार' hasBullet={true}>
          <AsideMenuItem
            to='/settings/facility'
            title='सुविधा'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItemWithSub to='/settings/water' title='पानी' hasBullet={true}>
            <AsideMenuItem
              to='/settings/drinking-water-status'
              title='पिउने पानीको स्थिति'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/water-filteration-process'
              title='पानी फिल्टर प्रक्रिया'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/drinking-water-source'
              title='पिउने पानीको स्रोत'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/settings/waste-water-management'
            title='फोहोर पानी व्यवस्थापन'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/waste-management'
            title='फोहोर व्यवस्थापन'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/toilet-type'
            title='शौचालय प्रकार'
            fontIcon='bi-layers'
            hasBullet={true}
          />

          <AsideMenuItem
            to='/settings/community-type'
            title='समुदाय प्रकार'
            fontIcon='bi-layers'
            hasBullet={true}
          />

          <AsideMenuItem to='/settings/stove' title='चुला' fontIcon='bi-layers' hasBullet={true} />
          <AsideMenuItem
            to='/settings/vehicle-type'
            title='गाडीको प्रकार'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/light-source'
            title='बत्तीको स्रोत'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/communication-technology'
            title='सञ्चार माध्यम'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/health-organization-type'
            title='स्वास्थ्य सँस्था प्रकार'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/school-type'
            title='विद्यालय प्रकार'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/education-area'
            title='शिक्षा क्षेत्र'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/transportation-means'
            title='यातायात साधन'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/cooking-fuel'
            title='खाना पकाउन प्रयोग हुने इन्धन'
            fontIcon='bi-layers'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/settings/agri-and-livestock'
          title='कृषि तथा पशुपंक्षी'
          hasBullet={true}
        >
          <AsideMenuItemWithSub to='/settings/crop' title='बाली' hasBullet={true}>
            <AsideMenuItem
              to='/settings/crop-category'
              title='बाली वर्ग'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/crop'
              title='बालीका प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/pesticide-usage-method'
              title='कीटनाशक प्रयोग विधि'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/settings/livestock' title='पशुपन्छी' hasBullet={true}>
            <AsideMenuItem
              to='/settings/livestock-business-type'
              title='पशुपन्छी व्यापारको प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/settings/livestock-type'
              title='पशुपन्छी प्रकार'
              fontIcon='bi-layers'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/settings/address' title='ठेगाना' hasBullet={true}>
          <AsideMenuItem to='/settings/tole' title='टोल' fontIcon='bi-layers' hasBullet={true} />
          <AsideMenuItem to='/settings/country' title='देश' fontIcon='bi-layers' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/settings/other' title='अन्य' hasBullet={true}>
          <AsideMenuItem
            to='/settings/fiscal_year'
            title='आर्थिक वर्ष'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/organization-type'
            title='संस्था प्रकार'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/natural-disaster'
            title='प्राकृतिक प्रकोप'
            fontIcon='bi-layers'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/settings/units' title='एकाइहरु' hasBullet={true}>
          <AsideMenuItem to='/settings/unit' title='एकाइ' fontIcon='bi-layers' hasBullet={true} />
          <AsideMenuItem
            to='/settings/unitType'
            title='एकाइ प्रकार'
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/settings/unitVariety'
            title='एकाइ विविधता'
            fontIcon='bi-layers'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
    </>
  )
}
