import {useEffect} from 'react'
import API from '@utils/api'
import {ADMIN_URL, API_PATHS} from '@api/paths'
import {queryKeys} from '@constants/queryKeys'
import {useQuery} from '@tanstack/react-query'
import {queryOptions} from '@utils/queryOptions'
import {Dashboard} from '@models/global'
import {Loading} from '@components/Loading'
import {PageLink, PageTitle} from '_metronic/layout/core'
import {DynamicSimpleTable} from '@components/table/DynamicSimpleTable'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'गृहपृष्ठ',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardPage = () => {
  const getDashboard = new API(`${ADMIN_URL}/${API_PATHS.dashboard}`)

  const {isLoading: queryLoading, data: dashboardData} = useQuery<Dashboard>(
    [queryKeys.dashboard],
    () => getDashboard.get(),
    {
      ...queryOptions,
    }
  )
  useEffect(() => {
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      {queryLoading ? (
        <div className='py-5'>
          <Loading />
        </div>
      ) : (
        <div className='row gy-5 g-xl-8'>
          <div className='col-xxl-4'>
            <div className='card'>
              <div className='card-body'>
                <div className='card-rounded'>
                  <div className='row g-0 mb-7'>
                    <div className='col mx-5'>
                      <div className='fs-6 text-gray-400'>जम्मा घरधुरी</div>
                      <div className='fs-2 fw-bold text-gray-800'>
                        {dashboardData?.householdCount}
                      </div>
                    </div>
                    <div className='col mx-5'>
                      <div className='fs-6 text-gray-400'>जम्मा जनसंख्या</div>
                      <div className='fs-2 fw-bold text-gray-800'>
                        {dashboardData?.populationCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xxl-4'>
            <div className='card'>
              <div className='card-body'>
                <div className='card-rounded'>
                  <DynamicSimpleTable
                    headData={
                      <>
                        <th>क्र.स</th>
                        <th>प्रयोगकर्ता</th>
                        <th>गतिविधि</th>
                        <th>आइ.पी</th>
                        <th>समय</th>
                      </>
                    }
                    bodyData={
                      <>
                        {dashboardData?.activities.map((data, index) => {
                          return (
                            <tr key={data.id}>
                              <td>{index + 1}</td>
                              <td>{data.user}</td>
                              <td>
                                {data.event} {data.auditable ? `(${data.auditable})` : ''}
                              </td>
                              <td>{data.ip_address}</td>
                              <td>{data.created_at}</td>
                            </tr>
                          )
                        })}
                      </>
                    }
                    isLoading={false}
                    colSpan={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const DashboardWrapper = () => {
  // const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>ड्यासबोर्ड</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
