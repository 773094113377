import React from 'react'
import {Loading} from '../Loading'
import {toAbsoluteUrl} from '_metronic/helpers'

type DynamicSimpleTableProps = {
  headData: React.ReactNode
  bodyData: React.ReactNode
  isLoading: boolean
  colSpan: number
  isEmpty?: boolean
}

export const DynamicSimpleTable: React.FC<DynamicSimpleTableProps> = ({
  headData,
  bodyData,
  isLoading,
  colSpan,
  isEmpty,
}) => {
  return (
    <div className='table-responsive'>
      <table className='table table-sm table-bordered'>
        <thead>
          <tr>{headData}</tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={colSpan}>
                <Loading />
              </td>
            </tr>
          ) : isEmpty ? (
            <tr>
              <td colSpan={colSpan} className='text-center'>
                <img
                  alt='No File'
                  src={toAbsoluteUrl('/media/svg/no-data.svg')}
                  className='h-150px'
                />
              </td>
            </tr>
          ) : (
            <>{bodyData}</>
          )}
        </tbody>
      </table>
    </div>
  )
}
