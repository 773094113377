import {AsideMenuItem} from '../AsideMenuItem'

export function UserRoutes() {
  return (
    <>
      <AsideMenuItem
        to='/user-management/role'
        icon='check-circle'
        title='भूमिका'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/user-management/user-list'
        icon='check-circle'
        title='प्रयोगकर्ताहरू'
        fontIcon='bi-layers'
      />
    </>
  )
}
